import * as React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import Modal from "../components/modal"
import ThemeToggle from "./themeToggle";
import {useIntl} from "react-intl";

import github from '../images/icons/github-brands.svg'
import linkedin from '../images/icons/linkedin-in-brands.svg'
import instagram from '../images/icons/instagram-brands.svg'
import facebook from '../images/icons/facebook-f-brands.svg'
import {LocalizedLink} from "gatsby-theme-i18n";

const Footer = () => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});

    const images = useStaticQuery(graphql`
    query {
      dumaWhite: file(relativePath: { eq: "duma-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return <footer
        className='flex flex-col md:flex-row justify-between items-center w-full px-6 md:px-14 z-auto'
        style={{backgroundColor: "#000000"}}>
        <div className='order-2 md:order-1 flex justify-evenly flex-col items-center md:items-start py-5'>
            <LocalizedLink to='/'>
                <Img
                    style={{height: 24, width: 61}}
                    fluid={images.dumaWhite.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </LocalizedLink>
            <p className='text-white font-Regular text-center md:text-left text-sm'>
                {f("copyright")}
            </p>
        </div>
        <div className='order-3 md:order-2 flex flex-col py-5'>
            <p className='text-white font-Regular text-md text-center md:text-left'>{f("legal")}</p>
            <Modal/>
        </div>
        <div className='order-4 md:order-3 flex flex-col py-5'>
            <p className='text-white font-Regular text-md text-center md:text-left mb-1'>{f("contactMe")}</p>
            <p className='text-white font-ExtraLight text-md cursor-text text-center md:text-left'>diego@duma.studio</p>
        </div>
        <div className='order-5 md:order-4 flex flex-col items-center md:items-start py-5'>
            <p className='text-white font-Regular text-md text-center md:text-left mb-1'>{f("getInTouchAnotherWay")}</p>
            <div className='flex justify-between items-center w-52'>
                <a target="_blank" rel='noreferrer' href='https://github.com/du-ulises'>
                    <img src={github} alt='GitHub' width={16} height={16}/>
                </a>
                <a target="_blank" rel='noreferrer' href='https://www.linkedin.com/in/diegoulises'>
                    <img src={linkedin} alt='Linked In' width={16} height={16}/>
                </a>
                <a target="_blank" rel='noreferrer' href='https://www.instagram.com/du.ulises'>
                    <img src={instagram} alt='Instagram' width={16} height={16}/>
                </a>
                <a target="_blank" rel='noreferrer' href='https://www.facebook.com/du.ulises'>
                    <img src={facebook} alt='Facebook' width={12} height={16}/>
                </a>
            </div>
        </div>
        <div className='order-1 md:order-4 py-5'>
            <ThemeToggle/>
        </div>
    </footer>
}
export default Footer;
