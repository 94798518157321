import React from "react";
import {useColorMode} from "theme-ui"
import {ThemeToggler} from "gatsby-plugin-dark-mode";

const ColorModeToggle = () => {

    const [colorMode, setColorMode] = useColorMode()
    const isDark = colorMode === `dark`

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    React.useLayoutEffect(() => {
        if(prefersDark){
            if(localStorage.getItem('theme') === 'light'){
                setColorMode('light')
            }else{
                setColorMode('dark')
            }
        }
    }, [setColorMode, prefersDark]);

    return (
        <ThemeToggler>
            {({toggleTheme}) => (
                <div className='flex items-center'>
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            setColorMode(isDark ? `light` : `dark`)
                            toggleTheme(isDark ? `light` : `dark`)
                        }}
                        type="button"
                        aria-label={isDark ? `Activate Light mode` : `Activate Dark mode`}
                        title={isDark ? `Activate Light mode` : `Activate Dark mode`}
                        className='btn-toggle-out'
                    >
                        <div
                            className={isDark ? 'btn-toggle-in-dark' : 'btn-toggle-in'}
                        />
                    </button>
                </div>
            )}
        </ThemeToggler>
    )
}

export default ColorModeToggle
