import React from 'react';
import {useIntl} from "react-intl";
import {LocalizedLink} from "gatsby-theme-i18n";

const Menu = () => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    return (
        <>
            <button className='h-6 w-6' aria-label="Menu" onClick={() => {
                setNavbarOpen(!navbarOpen)
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"/>
                </svg>
            </button>
            <aside
                className={`transform top-0 right-0 w-64 md:w-80 bg-black dark:bg-blue fixed h-full overflow-auto ease-in-out transition-all duration-300 z-50 ${navbarOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className='flex justify-end items-center h-90px pr-6 pl-6 md:pr-14 md:pl-14'>
                    <button className='h-6 w-6' aria-label="Close" onClick={() => {
                        setNavbarOpen(!navbarOpen)
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                             className='stroke-current text-white'>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                </div>
                <LocalizedLink to='/'
                               className='flex justify-end items-center h-70px pr-6 pl-6 md:pr-14 md:pl-14 font-SemiBold text-white'>
                    {f("home")}
                </LocalizedLink>
                <LocalizedLink to='/portfolio'
                               className='flex justify-end items-center h-70px pr-6 pl-6 md:pr-14 md:pl-14 font-SemiBold text-white'>
                    {f("portfolio")}
                </LocalizedLink>
                <LocalizedLink to='/testimonials'
                               className='flex justify-end items-center h-70px pr-6 pl-6 md:pr-14 md:pl-14 font-SemiBold text-white'>
                    {f("testimonials")}
                </LocalizedLink>
                <LocalizedLink to='/resume'
                               className='flex justify-end items-center h-70px pr-6 pl-6 md:pr-14 md:pl-14 font-SemiBold text-white'>
                    {f("resume")}
                </LocalizedLink>
            </aside>
        </>
    );
};

export default Menu;
