import React from "react";
import Img from "gatsby-image"
import {useStaticQuery, graphql} from "gatsby"
import {useColorMode} from "theme-ui"
import LanguageToggle from "./languageToggle";
import Menu from "./menu";
import {LocalizedLink} from "gatsby-theme-i18n";

const Header = ({location, color}) => {
    const [colorMode] = useColorMode()
    const isDark = colorMode === `dark`

    const images = useStaticQuery(graphql`
    query {
      dumaBlack: file(relativePath: { eq: "duma-black.png" }) {
        childImageSharp {
          fluid(maxWidth: 520) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dumaWhite: file(relativePath: { eq: "duma-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 520) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return <header className='sticky top-0 z-40'>
        <div
            className="flex justify-between items-center w-screen h-90px pr-6 pl-6 md:pr-14 md:pl-14 bg-white-transparent dark:bg-black-transparent">
            <div className='hidden md:block'>
                <LanguageToggle location={location} color={color}/>
            </div>
            <div className={'flex items-center'}>
                <LocalizedLink to='/'>
                    <Img
                        style={{height: 36, width: 80}}
                        fluid={isDark ? images.dumaWhite.childImageSharp.fluid : images.dumaBlack.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                </LocalizedLink>
                <div className='block md:hidden ml-3.5'>
                    <LanguageToggle location={location} color={color}/>
                </div>
            </div>
            <Menu/>
        </div>
    </header>
}

export default Header;
