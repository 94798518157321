import React from "react";
import {useIntl} from "react-intl";

export default function Modal() {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});
    const [showModal, setShowModal] = React.useState(false);
    return (
        <>
            <button className='cursor-pointer outline-none focus:outline-none' onClick={() => setShowModal(true)} aria-label='Cookies Policy'>
                <p className='text-white font-ExtraLight text-md text-center md:text-left'>{f("cookiesPolicy")}</p>
            </button>
            {showModal ? (
                <>
                    <button
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-full"
                        onClick={() => setShowModal(false)}
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div
                                className="border-0 rounded-10px shadow-lg relative flex flex-col bg-white dark:bg-gray outline-none focus:outline-none mx-6">
                                <div className="relative py-8 px-6 md:px-20 md:py-20 flex-auto">
                                    <h1 className='font-ExtraBold text-3xl md:text-5xl text-center'>{f("cookiesPolicyTitle")}</h1>
                                    <p className="font-Regular mt-4 md:mt-6 md:text-xl leading-relaxed text-center">{f("policy")} </p>
                                </div>
                            </div>
                        </div>
                    </button>
                    <div className="opacity-75 fixed inset-0 z-40 bg-black"/>
                </>
            ) : null}
        </>
    );
}