import React from 'react';
import SEO from "./seo";
import {useIntl} from "react-intl";

const Loader = () => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});

    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-black">
            <SEO title={f("home")}/>
            <span className="animate-spin h-5 w-5 mb-3 border-b-2 border-white rounded-full"/>
            <span className='font-Regular text-white mb-10'>{f("loading")}</span>
        </div>
    );
};

export default Loader;
