import React from "react";
import {motion} from "framer-motion";
import {LocalizedLink, useLocalization} from "gatsby-theme-i18n";

const LanguageToggle = ({location, color}) => {
    const {locale} = useLocalization()
    let to = locale === 'en' ? location.pathname : location.pathname.substr(3)
    return (
        <motion.div
            className="flex justify-center items-center divide-x font-Medium text-xs md:text-sm"
            initial={{y: -100}}
            animate={{y: 0}}
            transition={{delay: 0.2, type: "spring", stiffness: 120}}
        >
            <motion.div
                whileHover={{
                    scale: 1.1,
                    originX: 0,
                    color: color,
                }}
                transition={{type: "spring", stiffness: 300}}
                className='pr-0.5 md:pr-1.5'
            >
                <LocalizedLink to={to} language="es" style={{color: locale === 'es' ? color : ''}}>
                    ES
                </LocalizedLink>
            </motion.div>
            <motion.div
                whileHover={{
                    scale: 1.1,
                    originX: 0,
                    color: color,
                }}
                transition={{type: "spring", stiffness: 300}}
                className='pl-0.5 md:pl-1.5'
            >
                <LocalizedLink to={to} language="en" style={{color: locale === 'en' ? color : ''}}>
                    EN
                </LocalizedLink>
            </motion.div>
        </motion.div>
    );
};

export default LanguageToggle;
